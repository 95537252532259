.jsoneditor-menu {
  visibility: hidden;
}
.edit-section-inner {
  height: calc(100% - 36px);
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -ms-flex-direction: column;
  -moz-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}
div.jsoneditor-outer.has-main-menu-bar {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
}
.jsoneditor {
  border: none;
  height: auto !important;
  flex: 1;
}
.ace-chrome {
  background-color: #fafafa;
  top: -32px;
}
.ace-gutter {
  border-right: 2px solid #ebebeb;
  background-color: #fafafa !important;
}
.ace_layer {
  white-space: pre-wrap;
}
.jsoneditor-statusbar {
  background-color: #f4f4f4;
}
.ace_gutter-layer {
  background-color: #fafafa !important;
  border-right: 1px solid #ebebeb;
}
.ace_scroller {
  background-color: #fafafa !important;
  border-right: 1px solid #ebebeb;
}
.jsoneditor-statusbar {
  border-top: 1px solid #ebebeb;
  border-right: 1px solid #ebebeb;
}
div.jsoneditor-outer.has-main-menu-bar {
  margin-top: -35px !important;
}
.ace_variable {
  color: #9c2aa0 !important;
}
.ace_boolean {
  color: #00b0ca !important;
}
.ace_numeric {
  color: #a8b400 !important;
}
.ace_string {
  color: #333333 !important;
}
.jsoneditor-curserinfo-label {
  font-family: "Vodafone Rg";
  font-size: 12px;
  color: #afafaf;
}
.jsoneditor-curserinfo-val {
  font-family: "Vodafone Rg";
  font-size: 12px;
  color: #afafaf;
}
.jsoneditor-curserinfo-count {
  font-family: "Vodafone Rg";
  font-size: 12px;
  color: #afafaf;
}
.ace_gutter-cell {
  color: #afafaf;
  font-size: 14px;
}
.jsoneditor-statusbar > .jsoneditor-parse-error-icon {
  background: url("../images/serviceEditor/cancel.svg");
}
.ace_gutter-cell.ace_error {
  background: url("../images/serviceEditor/cancel.svg");
  background-position: 0px center;
  background-repeat: no-repeat;
}
.jsoneditor-statusbar > .jsoneditor-validation-error-icon {
  background: url("../images/serviceEditor/warning.svg");
  background-position: center;
  background-repeat: no-repeat;
}
.ace_gutter-cell.ace_warning {
  background: url("../images/serviceEditor/warning.svg");
  background-position: 0px center;
  background-repeat: no-repeat;
}
.jsoneditor-validation-error-count {
  font-family: "Vodafone Rg";
  font-size: 12px;
  color: #eb9700;
}
.jsoneditor .jsoneditor-text-errors tr,
.jsoneditor .jsoneditor-text-errors tr.parse-error {
  background-color: #fafafa;
}
.jsoneditor .jsoneditor-text-errors,
.jsoneditor-text-errors {
  border-top: 1px solid #ebebeb;
}
.ace-chrome .ace_marker-layer .ace_active-line {
  background: #fce5e5 !important;
}
.ace_search.right {
  position: relative;
  z-index: 1;
  background: #f4f4f4;
  border: none;
  border-right: 1px solid #ebebeb !important;
  display: -webkit-flex;
  padding-bottom: 11px !important;
  border-radius: 0px !important;
}
.ace_searchbtn_close {
  display: none;
}

.ace_searchbtn:last-child {
  display: none;
}
.ace_search_form {
  flex: 1;
  height: 36px;
  border-radius: 6px;
  border: solid 1px #ebebeb;
  background-color: #ffffff;
  display: grid;
  grid-template-areas:
    "input input input input counter top"
    "input input input input counter bottom";
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.ace_search_counter {
  grid-area: counter;
}
.ace_search_form:hover {
  border: solid 1px #333333;
}
.ace_replace_form:hover {
  border: solid 1px #333333;
}
.ace_search_field {
  width: 93%;
  min-width: auto !important;
  grid-area: input;
  height: -webkit-fill-available;
  border: none !important;
  font-family: "Vodafone Rg";
  font-size: 16px;
}
.ace_search_field::-ms-clear {
  display: none;
}

.ace_searchbtn {
  display: none !important;
}
.ace_searchbtn.next {
  display: block !important;
  height: 100%;
  grid-area: bottom;
  border: none;
  height: 8px !important;
  padding: 0 !important;
  padding-bottom: 9px !important;
  padding-top: 0px !important;
  width: 20px !important;
  margin: auto !important;
  margin-right: 4px !important;
}
.ace_searchbtn.next:after {
  display: block !important;
  transform: rotate(45deg) !important;
  border-width: 0 1px 1px 0 !important;
}

.ace_searchbtn.prev {
  display: block !important;
  height: 100%;
  grid-area: top;
  border: none;
  height: 8px !important;
  padding: 0 !important;
  padding-top: 9px !important;
  padding-bottom: 0px !important;
  width: 20px !important;
  margin: auto !important;
  margin-right: 4px !important;
}

.ace_searchbtn.prev:after {
  display: block !important;
  transform: rotate(45deg) !important;
  border-width: 1px 0 0 1px !important;
}
.ace_searchbtn:hover {
  background-color: transparent !important;
}
.ace_replace_form {
  flex: 1;
  height: 36px;
  border-radius: 6px;
  border: solid 1px #ebebeb;
  background-color: #ffffff;
  display: -webkit-flex;
  margin-right: 18px !important;
}
.replaceBtn {
  border: none !important;
  margin-right: 8px !important;
  display: block !important;
}
.replaceBtn::after {
  content: "";
  border: solid 2px #888;
  width: 0.3em;
  height: 0.7em;
  display: block !important;
  transform: rotate(45deg) !important;
  margin-top: 9px;
  border-width: 0 1px 1px 0;
}

.ace_search_options {
  display: none;
}

.ace_search_form.ace_nomatch {
  outline: none !important;
  border: 1px solid #e60000;
}
.ace_search_counter {
  line-height: 34px;
  margin: auto;
  padding: 0;
  font-family: "Vodafone Rg";
  font-size: 12px;
  color: #afafaf;
}

.ql-toolbar {
  border: none !important;
}

.ql-container {
  border: none !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px";
  font-size: 10px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
  font-size: 12px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
  font-size: 14px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
  font-size: 16px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px";
  font-size: 18px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
  font-size: 20px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
  font-size: 24px !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "32px";
  font-size: 32px !important;
}

.ql-picker-label[data-value="10px"]::before {
  content: "10px" !important;
}
.ql-picker-label[data-value="12px"]::before {
  content: "12px" !important;
}
.ql-picker-label[data-value="14px"]::before {
  content: "14px" !important;
}
.ql-picker-label[data-value="16px"]::before {
  content: "16px" !important;
}
.ql-picker-label[data-value="18px"]::before {
  content: "18px" !important;
}
.ql-picker-label[data-value="20px"]::before {
  content: "20px" !important;
}
.ql-picker-label[data-value="24px"]::before {
  content: "24px" !important;
}
.ql-picker-label[data-value="24px"]::before {
  content: "24px" !important;
}
.ql-picker-label[data-value="32px"]::before {
  content: "32px" !important;
}

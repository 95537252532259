
.sortable-ghost{
    border: 1px #cccccc dashed !important;
    cursor: move;
}

.sortable-chosen{
    background-color: #f4f4f4;
    border: 1px #cccccc solid;
    opacity: 1 !important;
    cursor: move;
}

.handle{
    cursor: move;
    cursor: -webkit-grabbing;
}

.tooltip-container{
    border-radius: 6px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
/* 
.login-animated-img-enter-active {
  animation-duration: 1s;
  animation-name: fadeIn;
} */

.login-animated-img-exit {
  animation-duration: 1s;
  animation-name: fadeOut;
}

.login-animated-img-exit-active {
  opacity: 0;
}
